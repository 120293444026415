var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "upload-component-class" },
    [
      _c("el-upload", {
        ref: "uploadFile",
        staticClass: "upload-demo",
        attrs: { action: "/api/file/upload", multiple: "", limit: 9999999 },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleAddFolder($event)
                      },
                    },
                  },
                  [_vm._v("头像批量导入")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }