var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "avatar-uploader",
          attrs: {
            action: "/trading-market-admin/file/upload",
            headers: _vm.config,
            name: "file",
            "list-type": "picture-card",
            "on-success": _vm.handleAvatarSuccess,
            "on-progress": _vm.handleLoading,
            "on-error": _vm.handleError,
            "on-remove": _vm.handleRemove,
            "on-change": _vm.handleChange,
            "file-list": _vm.fileList,
            "before-upload": _vm.beforeAvatarUpload,
          },
        },
        [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }