import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.constructor";
export function isvalidUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
/* 合法uri*/

export function validateURL(textval) {
  var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}
export function validateHttpURL(str_url) {
  // 验证url
  var strRegex = "^((https|http|ftp|rtsp|mms)?://)" + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" // ftp的user@
  + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
  + "|" // 允许IP和DOMAIN（域名）
  + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
  + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
  + "[a-z]{2,6})" // first level domain- .com or .museum
  + "(:[0-9]{1,4})?" // 端口- :80
  + "((/?)|" // a slash isn't required if there is no file name
  + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  var re = new RegExp(strRegex);
  return re.test(str_url);
}
/* 小写字母*/

export function validateLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
/* 大写字母*/

export function validateUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
/* 大小写字母*/

export function validatAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
/*验证pad还是pc*/

export var vaildatePc = function vaildatePc() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  var flag = true;

  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }

  return flag;
}; //转换时间格式

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

;
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };

  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }

  return fmt;
}
;
/**
 * validate email
 * @param email
 * @returns {boolean}
 */

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
} // //  银行卡号
// export function validatebankNo(back){
//   const str =  /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
//   let msg = ''
//   if(!back == ''){
//     if(!str.test(back)){
//       msg='格式不正确！'
//     }
//   }else{
//     msg = '请填写卡号！'
//   }
// return msg
// }

/**
 * 判断身份证号码
 */

export function cardid(code) {
  // let list = [];
  // let result = true;
  var msg = '';
  var city = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外 "
  };

  if (!validatenull(code)) {
    if (code.length == 18) {
      if (!code || !/(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(code)) {
        msg = "证件号码格式错误!";
      } else if (!city[code.substr(0, 2)]) {
        msg = "地址编码错误！";
      } else {
        //18位身份证需要验证最后一位校验位
        code = code.split(''); //∑(ai×Wi)(mod 11)
        //加权因子

        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; //校验位

        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2, 'x'];
        var sum = 0;
        var ai = 0;
        var wi = 0;

        for (var i = 0; i < 17; i++) {
          ai = code[i];
          wi = factor[i];
          sum += ai * wi;
        }

        var last = parity[sum % 11];

        if (parity[sum % 11] != code[17]) {
          msg = "证件号码校验位错误!";
        }
      }
    } else {
      msg = "证件号码长度不为18位!";
    }
  } else {
    msg = "证件号码不能为空!";
  } // list.push(result);
  // list.push(msg);


  return msg;
}
/**
 * 判断手机号码是否正确
 */

export function isvalidatemobile(phone) {
  var msg = '';
  var isPhone = /^0\d{2,3}-?\d{7,8}$/; //增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]

  var isMob = /^((\+?86)|(\(\+86\)))?(13[0123456789][0-9]{8}|15[012356789][0-9]{8}|18[012356789][0-9]{8}|14[57][0-9]{8}|17[3678][0-9]{8})$/;

  if (!validatenull(phone)) {
    if (!isMob.test(phone)) {
      msg = '电话号码格式不正确！请输入十一位数字号码！';
    }
  } else {
    msg = '电话号码不能为空！';
  }

  return msg;
}
export function isvalidatemobileGai(phone) {
  var msg = '';
  var isPhone = /^0\d{2,3}-?\d{7,8}$/; //增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]

  var isMob = /^((\+?86)|(\(\+86\)))?(13[0123456789][0-9]{8}|15[012356789][0-9]{8}|18[012356789][0-9]{8}|14[57][0-9]{8}|17[3678][0-9]{8}|19[0123456789][0-9]{8})$/;

  if (!validatenull(phone)) {
    if (!isMob.test(phone)) {
      msg = '电话号码格式不正确！';
    }
  } else {
    msg = '电话号码不能为空！';
  }

  return msg;
}
export function isvalidatemobileGaiTow(phone) {
  var msg = '';
  var isPhone = /^0\d{2,3}-?\d{7,8}$/; //增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]

  var isMob = /^((\+?86)|(\(\+86\)))?(1[123456789][0123456789][0-9]{8})$/;

  if (!validatenull(phone)) {
    if (!isMob.test(phone)) {
      msg = '电话号码格式不正确！';
    }
  } else {
    msg = '电话号码不能为空！';
  }

  return msg;
}
export function isvalidateIpone(phone) {
  var msg = '';
  var isPhone = /^0\d{2,3}-?\d{7,8}$/; //增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]

  var isMob = /^((\+?86)|(\(\+86\)))?(1[123456789][0123456789][0-9]{8}|15[012356789][0-9]{8}|16[012356789][0-9]{8}|18[012356789][0-9]{8}|14[57][0-9]{8}|17[3678][0-9]{8})$/;

  if (!validatenull(phone)) {
    if (!isMob.test(phone)) {
      msg = '格式错误！请输入正确的手机号';
    }
  } else {
    msg = '手机不号能为空！';
  }

  return msg;
}
export function isvalidateIpone1(phone) {
  var msg = '';
  var isPhone = /^0\d{2,3}-?\d{7,8}$/; //增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]

  var isMob = /^((\+?86)|(\(\+86\)))?(1[123456789][0123456789][0-9]{8}|15[012356789][0-9]{8}|16[012356789][0-9]{8}|18[012356789][0-9]{8}|14[57][0-9]{8}|17[3678][0-9]{8})$/;

  if (!validatenull(phone)) {
    if (!isMob.test(phone)) {
      msg = '格式错误！请输入正确的账号';
    }
  } else {
    msg = '账号不能为空！';
  }

  return msg;
}
/**
 * 判断姓名是否正确
 */

export function validatename(name) {
  var msg = '';
  var regName = /^[\u4e00-\u9fa5]{2,4}$/; // re.compile("[^\u4e00-\u9fa5^a-z^A-Z^0-9]")

  if (!name == '') {
    if (!regName.test(name)) {
      msg = '格式不正确！';
    }
  } else {
    msg = '姓名不能为空!';
  }

  return msg;
}
; //  银行卡号

export function validatebankNo(back) {
  var str = /^([1-9]{1})(\d{15}|\d{16}|\d{18}|\d{19}|\d{20})$/;
  var msg = '';

  if (!back == '') {
    if (!str.test(back)) {
      msg = '格式不正确！';
    }
  } else {
    msg = '请填写卡号！';
  }

  return msg;
}
/**
 * 判断是否为整数
 */

export function validatenum(num, type) {
  var regName = /[^\d.]/g;

  if (type == 1) {
    if (!regName.test(num)) return false;
  } else if (type == 2) {
    regName = /[^\d]/g;
    if (!regName.test(num)) return false;
  }

  return true;
}
;
/**
 * 判断是否为小数
 */

export function validatenumord(num, type) {
  var regName = /^((([^0][0-9]+|0)\.([0-9]{1,20}))$)|^(([1-9]+)\.([0-9]{1,20})$)/;
  if (regName.test(num)) return false;
}
;
/**
 * 判断是否为空
 */

export function validatenull(val) {
  if (typeof val == 'boolean') {
    return false;
  }

  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
    return false;
  }

  return false;
}
;