import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
import DictApi from '@/api/systemManage/DictManageApi';
import _ from 'lodash';
export default {
  name: 'ForeignSelect',
  data: function data() {
    return {
      dataSource: [],
      text: this.multiple ? [] : ''
    };
  },
  props: {
    value: [String, Number, Array],
    size: {
      type: String,
      default: 'medium'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    labelKey: {
      type: String,
      default: 'dictName'
    },
    valueKey: {
      type: String,
      default: 'dictId' // default: 'dictCode',

    },
    dictTypeCode: {
      type: String
    },
    showAll: {
      type: Boolean,
      default: false
    },
    parentCode: {
      type: String,
      default: '-1'
    }
  },
  methods: {
    findDict: function findDict() {
      var _this = this;

      var params = {};
      params.dictTypeCode = this.dictTypeCode;
      var dictApi = new DictApi();

      if (this.showAll) {
        // 如果现实该字典类型全部数据
        dictApi.getDictListByTypeCode(params).then(function (res) {
          _this.dataSource = res.data;
        });
      } else {
        // 否则现实指定父级下的数据
        params.parentCode = this.parentCode;
        dictApi.getListByTypeCodeAndPCode(params).then(function (res) {
          _this.dataSource = res.data;
        });
      }
    },
    //选择回调
    handleChange: function handleChange(val) {
      this.text = val;
      this.$emit("input", val);
      var valueKey = this.valueKey;

      if (_.isArray(val)) {
        // 如果是多选
        var selectVals = [];
        this.dataSource.forEach(function (item) {
          if (val.indexOf(item[valueKey]) != -1) {
            selectVals.push(item);
          }
        });
        this.$emit("on-change", selectVals);
      } else {
        // 如果是单选
        var dict = {};
        this.dataSource.forEach(function (item) {
          if (item[valueKey] == val) {
            dict = item;
          }
        });
        this.$emit("on-change", dict);
      }
    }
  },
  created: function created() {
    this.findDict();
    this.text = this.value;
  },
  watch: {
    value: function value(curVal, oldVal) {
      this.text = curVal;
    },
    parentCode: function parentCode(curVal, oldVal) {
      this.findDict();
    }
  },
  computed: {}
};