import "core-js/modules/es6.regexp.split";
import axios from "axios";
import StorageHandler from "../utils/StorageHandler";
import SystemConfig from "../config/SystemConfig";
import { Message } from "element-ui"; //默认请求响应超时时间

axios.defaults.timeout = 10000 * 60; //跨域请求，允许保存cookie

axios.defaults.withCredentials = true;
var storageHandlers = new StorageHandler(); //HTTPrequest拦截

axios.interceptors.request.use(function (config) {
  // 设置请求格式
  //config.headers["Content-Type"] = "application/json";
  // 设置token
  var storageHandler = new StorageHandler();

  if (storageHandler.getSessionStorage('userInfoFiance') && storageHandler.getSessionStorage('userInfoFiance').token) {
    config.headers["Authorization"] = storageHandler.getSessionStorage('userInfoFiance').token;
  } else {
    config.headers["Authorization"] = null;
  } // config.headers["Authorization"] = '1'
  // 是否需要签名


  if (SystemConfig.sign) {
    var signHanler = new SignHanler();
    signHanler.handler(config);
  }

  return config;
}, function (error) {
  console.log("err" + error); // for debug

  return Promise.reject(error);
}); //HTTPresponse拦截

axios.interceptors.response.use(function (data) {
  var res = data.data;

  if (res) {
    res.header = data.headers;
  }

  if (res.code === 10006) {
    Message({
      message: '登录已过期，请重新登录',
      type: 'warning'
    });
    storageHandlers.clearSessionStorage();
    window.location.href = '/#/login';
  }

  return res;
}, function (error) {
  console.log(error);

  if (error.response.status === 401) {
    Message({
      showClose: true,
      message: error.response.data.message,
      type: 'error'
    });
    var url = window.location.href.split('?');
    storageHandlers.clearSessionStorage();
    return Promise.reject(error.response.data);
  } else {
    if (error.response.status === 500) {
      Message({
        showClose: true,
        message: error.response.data.message,
        type: "error"
      });
    }

    if (error.response.data.code == 401) {
      storageHandlers.clearSessionStorage();
      window.location.href = "/#/login";
    }

    return Promise.reject(error.response.data.message);
  }
});
export default axios;