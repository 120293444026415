import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StorageHandler from "@/utils/StorageHandler.js";
export default {
  data: function data() {
    return {
      storageHandler: new StorageHandler(),
      loading: false
    };
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '导入'
    },
    type: {
      type: String,
      default: 'primary'
    },
    uploadObjs: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  created: function created() {},
  computed: {
    config: function config() {
      return {
        Authorization: this.storageHandler.getSessionStorage("userInfoFiance").token
      };
    }
  },
  methods: {
    //上传之前
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // const isJPG =
      //   file.type === "image/jpeg" ||
      //   file.type === "image/jpg" ||
      //   file.type === "image/png";
      // if (!isJPG) {
      //   this.$message.error("上传的图片只能是 JPG/JPEG/PNG 格式！");
      // }
      // let isLt2M = file.size / 1024 / 1024 < 4
      // if (!isLt2M) {
      //   this.$message.error("文件不能大于4M！");
      // }
      var isTrue = true;
      var fileName = file.name;
      var fileType = fileName.substring(fileName.lastIndexOf('.'));

      if (fileType === '.xlsx' || fileType === '.xls') {
        isTrue = true;
      } else {
        this.$message.error('请导入正确的文件格式！');
        isTrue = false;
      }

      return isTrue;
    },
    //上传成功后
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      this.loading.close();

      if (response.code === 10006) {
        this.$message({
          message: '登录已过期，请重新登录',
          type: 'warning'
        });
        this.storageHandler.clearSessionStorage();
        this.$router.replace({
          path: '/login'
        });
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: response.data,
          type: 'success',
          duration: 0,
          showClose: true
        });
        this.$emit("inputSuccess");
      }
    },
    handleGoingFn: function handleGoingFn() {
      this.loading = this.$loading({
        lock: true,
        text: "导入中.....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }
  }
};