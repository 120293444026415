import _objectSpread from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import SidebarItem from "./sidebarItem";
import MenuApi from "@/api/MenuApi";
import StorageHandler from "@/utils/StorageHandler.js";
import SystemUtil from "@/utils/SystemUtil";
import manage from "@/api/infoMenu/infoMenu.js";
import { MessageBox } from 'element-ui';
export default {
  name: "SjSidebar",
  components: {
    SidebarItem: SidebarItem
  },
  data: function data() {
    return {
      http: new manage(),
      menuApi: new MenuApi(),
      activeIndex: "",
      storageHandler: new StorageHandler(),
      menus: [] // appId: "1301770031008395266",
      // collapseFlag:false,

    };
  },
  props: [],
  computed: _objectSpread({}, mapGetters(["collapseFlag", 'userInfo'])),
  created: function created() {},
  mounted: function mounted() {
    this.initData(); // let loadsec = JSON.parse(sessionStorage.getItem('selectedTags')); // 获取本地存储的值
    // console.log(loadsec)
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    // 初始化页面数据
    initData: function initData() {
      var _this = this;

      // 获取菜单数据
      this.menuApi.getLeftMenuList().then(function (res) {
        if (!_.isEmpty(res.data)) {
          var menuList2 = [];
          menuList2 = SystemUtil.convertMenus(res.data);
          _this.menus = menuList2;

          _this.$store.dispatch("setMenus", _this.menus);
        }
      });
      var currentTag = this.storageHandler.getSessionStorage("currentTag");

      if (currentTag) {
        this.activeIndex = currentTag.id;
      } else {
        this.activeIndex = this.$route.path;
      }
    },
    handleOpen: function handleOpen(key, keyPath) {
      console.log();
    },
    handleClose: function handleClose(key, keyPath) {
      console.log();
    }
  },
  watch: {
    $route: function $route(to, from) {
      var currentTag = this.storageHandler.getSessionStorage("currentTag");

      if (currentTag) {
        this.activeIndex = currentTag.id;
      }
    }
  }
};