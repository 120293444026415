import _objectSpread from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import manage from "@/api/infoMenu/device.js";
import { mapGetters } from 'vuex';
export default {
  data: function data() {
    var that = this;
    return {
      http: new manage(),
      title: "个人信息",
      isShow: true,
      loading: false,
      width: "50vw",
      formData: {},
      options: {
        sex: [{
          label: "男",
          value: "1"
        }, {
          label: "女",
          value: "0"
        }],
        userType: [{
          label: "个体",
          value: 1
        }, {
          label: "企业",
          value: 2
        }],
        cardType: [{
          label: "身份证",
          value: 1
        }, {
          label: "营业执照",
          value: 2
        }]
      },
      listTree: [],
      rules: {
        names: [{
          required: true,
          message: "请填写姓名"
        }],
        cardFront: [{
          required: true,
          message: "请上传证件照"
        }],
        cardBack: [{
          required: true,
          message: "请上传证件照"
        }],
        cardType: [{
          required: true,
          message: "请选择证件类型"
        }],
        contactsTel: [{
          required: true,
          message: "请输入正确格式的手机号！",
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        }],
        cardNo: [{
          required: true,
          message: "请输入正确格式的身份证号！",
          pattern: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
        }],
        address: [{
          required: true,
          message: "请输入详细地址！"
        }]
      },
      //省市区级联配置
      areaProps: {
        lazy: true,
        lazyLoad: function lazyLoad(node, resolve) {
          var level = node.level;
          that.http.findAreaByParents({
            parentId: node.value || -1
          }).then(function (res) {
            console.log(res.data);
            var nodes = res.data.map(function (item) {
              return {
                label: item.names,
                value: item.codes,
                leaf: level == 2,
                parentIds: item.parentIds
              };
            }); // 通过调用resolve将子节点数据返回，通知组件数据加载完成

            resolve(nodes);
          });
        }
      },
      action: 'view'
    };
  },
  props: [],
  created: function created() {
    this.getData();
  },
  watch: {},
  components: {},
  computed: _objectSpread({}, mapGetters(['userInfo'])),
  methods: {
    // 详情接口获取数据
    getData: function getData() {
      var _this = this;

      this.loading = true;
      this.http.getPersonalInfoFn({
        accountId: this.userInfo.id
      }).then(function (res) {
        if (res.code == 200) {
          _this.formData = res.data; //反显省市区

          _this.$set(_this.formData, 'provinceCodeList', [_this.formData.provinceCode, _this.formData.cityCode, _this.formData.countyCode]); // 


          _this.formData.cardType = 1;
        } else {
          _this.$message.error(res.message);
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    // 切换证件类型
    onChangeCardTypeFn: function onChangeCardTypeFn() {
      this.formData.cardBack = "";
      this.formData.cardFront = "";
      this.formData.businessLicense = "";
    },
    //确定按钮
    confirm: function confirm() {
      var _this2 = this;

      var that = this;
      that.$refs["formData"].validate(function (valid1) {
        if (valid1) {
          if (!_this2.formData.provinceCodeList.length) {
            _this2.$message.error("请选择所在地");

            return;
          } //校验到达地址列表有没有都填完


          that.doSave();
        } else {
          that.$message.error("请确认基本信息是否补充完善!");
          return;
        }
      });
    },
    doSave: function doSave() {
      var _this3 = this;

      var loading = this.$loading({
        lock: true,
        text: "请耐心等待.....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      }); //根据是否有id判断调用编辑还是新增

      var requestFn = this.formData.id ? "savePersonalInfoFn" : "savePersonalInfoFn"; // 处理省市区数据

      var area1Data = this.$refs.area1.getCheckedNodes()[0];
      this.formData.province = area1Data.pathLabels[0]; //赋值基本信息-省市区中文

      this.formData.provinceCode = area1Data.path[0]; //赋值基本信息-省市区中文

      this.formData.city = area1Data.pathLabels[1]; //赋值基本信息-省市区中文

      this.formData.cityCode = area1Data.path[1]; //赋值基本信息-省市区中文

      this.formData.county = area1Data.pathLabels[2]; //赋值基本信息-省市区中文

      this.formData.countyCode = area1Data.path[2]; //赋值基本信息-省市区中文

      var params = JSON.parse(JSON.stringify(this.formData)); // 删除没有用的属性

      delete params.provinceCodeList;
      this.http[requestFn](params).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success("操作成功!");

          _this3.$emit("close");
        } else {
          _this3.$message.error(res.message);
        }
      }).catch(function (e) {
        _this3.$message.error("错误异常！");
      }).finally(function () {
        loading.close();
      });
    }
  }
};