import StorageHandler from '@/utils/StorageHandler';
import SystemConfig from '@/config/SystemConfig';
var storage = new StorageHandler();
var state = {
  userInfo: storage.getStorage("userInfoFiance")
};
var mutations = {
  SET_USER_INFO: function SET_USER_INFO(state, data) {
    storage.setStorage("userInfoFiance", data, SystemConfig.storate.sessionStorageKey);
    state.userInfo = data;
  },
  CLEAR_USER_INFO: function CLEAR_USER_INFO(state, data) {
    state.userInfo = {};
    storage.removeStorage("userInfoFiance", SystemConfig.storate.sessionStorageKey);
  }
};
var actions = {
  //保存userInfo
  setUserInfo: function setUserInfo(_ref, data) {
    var commit = _ref.commit;
    commit('SET_USER_INFO', data);
  },
  //清除userInfo
  clearUserInfo: function clearUserInfo(_ref2, data) {
    var commit = _ref2.commit;
    commit('CLEAR_USER_INFO', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};