//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SjHeader from "./header/index";
import SjSidebar from "./sidebar/index";
import StorageHandler from "../utils/StorageHandler";
import ButtonMaintainApi from "@/api/systemManage/ButtonMaintainApi";
export default {
  components: {
    SjHeader: SjHeader,
    SjSidebar: SjSidebar
  },
  name: "index",
  data: function data() {
    return {};
  },
  created: function created() {
    var http = new ButtonMaintainApi(); // http.getAppButtonStatus({ appCode: "guns-cloud-system" }).then((res) => {
    //   // console.log(res.data)
    // });
  },
  mounted: function mounted() {},
  computed: {},
  props: [],
  methods: {}
};