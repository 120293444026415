import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
import StorageHandler from "@/utils/StorageHandler.js";
import p2pApi from "@/api/infoMenu/infoMenu.js";
export default {
  data: function data() {
    return {
      storageHandler: new StorageHandler(),
      fileList: [],
      Listimg: '',
      files: '',
      imgvidtf: 3
    };
  },
  computed: {
    config: function config() {
      return {
        "Authorization": this.storageHandler.getSessionStorage('tokenFiance')
      };
    }
  },
  props: ['url', 'value', 'isDetail'],
  watch: {
    url: function url(val) {
      if (val) {
        this.getiv(val);
      }
    },
    value: function value(curVal, oldVal) {
      var url = curVal.split(".");
      var arrimage = ['jpeg', 'jpg', 'png'];

      if (arrimage.indexOf(url[url.length - 1]) == -1) {
        this.imgvidtf = 1;
      } else {
        this.imgvidtf = 0;
      }

      this.Listimg = curVal;
    }
  },
  methods: {
    getiv: function getiv(val) {
      var img;

      if (val.fileType == 1) {
        img = 0;
      } else {
        img = 1;
      }

      this.imgvidtf = img;
      this.Listimg = val.imageUrl;
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      var img;
      var arrimage = ['image/jpeg', 'image/jpg', 'image/png'];
      var arrvideo = ['video/mp4', 'video/ogg', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'];

      if (arrimage.indexOf(this.files.type) == -1) {
        img = 1;
      } else {
        img = 0;
      }

      this.imgvidtf = img;
      this.Listimg = res.data.url;
      this.$emit('input', this.Listimg);
    },
    // 上传前回调
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var arr = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4', 'video/ogg', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'];

      if (arr.indexOf(file.type) == -1) {
        this.$message.error("请上传正确的图片视频格式");
        return false;
      }

      this.files = file;
    },
    handleError: function handleError(file, fileList) {
      this.$message.error('上传失败！');
    }
  }
};