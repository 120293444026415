var getters = {
  // sidebar: state => state.app.sidebar,
  // device: state => state.app.device,
  token: function token(state) {
    return state.common.token;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  homeTag: function homeTag(state) {
    return state.tags.homeTag;
  },
  selectedTags: function selectedTags(state) {
    return state.tags.selectedTags;
  },
  currentTag: function currentTag(state) {
    return state.tags.currentTag;
  },
  menus: function menus(state) {
    return state.common.menus;
  },
  collapseFlag: function collapseFlag(state) {
    return state.common.collapseFlag;
  },
  AnimalType: function AnimalType(state) {
    return state.common.AnimalType;
  }
};
export default getters;