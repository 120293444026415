import _toConsumableArray from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import _objectSpread from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//承保查询
import manage from "@/api/infoMenu/infoMenu.js";
import { mapGetters } from "vuex";
export default {
  data: function data() {
    return {
      http: new manage(),
      loadinglist: false,
      value: '',
      total: '',
      Pagesize: 10,
      breedoptions: [],
      limiData: {
        current: 1,
        size: 10,
        deptId: '',
        names: '',
        checkStatus: null
      },
      noMore: false
    };
  },
  props: {
    type: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    type: function type(val) {
      this.getList();
    }
  },
  created: function created() {},
  computed: _objectSpread({}, mapGetters(["userInfo"])),
  mounted: function mounted() {
    // this.$refs.select.$refs.scrollbar.$refs.wrap.addEventListener('scroll',this.scolling)
    // console.log(this.$refs.select)
    this.limiData.deptId = this.userInfo.companyId;

    if (this.type.id) {
      this.getList();
    }
  },
  components: {},
  methods: {
    getList: function getList() {
      var _this = this;

      this.limiData.names = this.type.name; // this.limiData.deptId = this.userInfo.companyId

      this.http.enterprisesgetList(this.limiData).then(function (res) {
        _this.breedoptions = res.data.records;
        _this.total = res.data.total;
        _this.value = _this.breedoptions.length == 0 ? _this.type.name : _this.type.id;
      });
    },
    remoteMethod: function remoteMethod(query) {
      var _this2 = this;

      if (query !== '') {
        this.loadinglist = true;
        this.limiData.names = query;
        this.limiData.current = 1;
        this.Pagesize = 10;
        this.http.enterprisesgetList(this.limiData).then(function (res) {
          _this2.loadinglist = false;
          _this2.total = res.data.total;
          _this2.breedoptions = res.data.records.filter(function (item) {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        });
      } else {
        this.breedoptions = [];
      }
    },
    loadMore: function loadMore() {
      var _this3 = this;

      if (this.Pagesize >= this.total) return false;
      this.limiData.current += 1;
      this.http.enterprisesgetList(this.limiData).then(function (res) {
        var _this3$breedoptions;

        var arr = res.data.records.filter(function (item) {
          return item.name.toLowerCase().indexOf(_this3.limiData.names.toLowerCase()) > -1;
        });
        _this3.Pagesize += arr.length;

        (_this3$breedoptions = _this3.breedoptions).push.apply(_this3$breedoptions, _toConsumableArray(arr));
      });
    },
    handleSelect: function handleSelect(item) {
      this.$emit('childFn', item);
    },
    scolling: function scolling() {
      console.log('789');
      var e = this.$refs.select.$refs.scrollbar.$refs.wrap;
      if (this.noMore) return;
      var loadMore = e.scrollHeight - e.scrollTop <= e.clientHeight;
      console.log(loadMore, '*');

      if (loadMore) {
        // this.loadMore()
        console.log('123');
      }
    }
  }
};