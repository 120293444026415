import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StorageHandler from "@/utils/StorageHandler";
export default {
  name: "SidebarItem",
  data: function data() {
    return {
      storageHandler: new StorageHandler()
    };
  },
  props: {
    menus: {
      type: Array
    },
    isCollapse: {
      type: Boolean
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  methods: {
    open: function open(item) {
      var reqUrl = item.menuUrl;

      if (item.menuUrl.indexOf("http") != -1 || item.menuUrl.indexOf("https") != -1) {
        reqUrl = "/myiframe/urlPath?src=".concat(reqUrl, "&name=").concat(item.name);
      }

      this.$router.push({
        path: reqUrl,
        query: item.query
      });
    }
  }
};