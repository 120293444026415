import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.math.trunc";
import "/root/workspace/trade-markert-v2_5BPH/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/workspace/trade-markert-v2_5BPH/node_modules/core-js/modules/es6.promise.js";
import "/root/workspace/trade-markert-v2_5BPH/node_modules/core-js/modules/es6.object.assign.js";
import "/root/workspace/trade-markert-v2_5BPH/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from "vue";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css

import "./styles/indexs.scss"; //  css

import "./assets/css/common.scss";
import "./assets/css/element-ui.scss";
import "./styles/theme/base.scss"; //更换主题css

import App from "./App";
import store from "./store";
import router from "./router";
import HttpConstant from "./const/HttpConstant";
import "./components/component";
import "./assets/iconfont/iconfont.css"; //引入iconfont

import moment from "moment";
import "./lib-flexible";
import "@/permission"; // permission control

import * as echarts from "echarts";
import "echarts/lib/component/polar";
import "echarts-gl"; //3D地图插件

import isMobile from "@/utils/DataTypeConvert";
import VueVideoPlayer from "vue-video-player";

require("video.js/dist/video-js.css");

require("vue-video-player/src/custom-theme.css");

import "video.js/dist/video-js.css";
import "videojs-contrib-hls";

var hls = require("videojs-contrib-hls");

Vue.use(hls);
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";
import * as RongIMLib from "@rongcloud/imlib-v4";
var im = RongIMLib.init({
  appkey: "4z3hlwrv42hbt"
});
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.prototype.$im = im;
Vue.prototype.$echarts = echarts;
Vue.prototype.isMobile = isMobile;
Vue.prototype.$EventBus = new Vue();
import "viewerjs/dist/viewer.css"; // import "swiper/swiper-bundle.css";

import Viewer from "v-viewer";
Vue.prototype.$ELEMENT = 30000;
import { installCardDragger } from "carddragger";
Vue.use(installCardDragger);
Vue.use(Viewer);
Vue.use(isMobile);
Vue.use(VueVideoPlayer);
Viewer.setDefaults({
  navbar: false,
  zIndex: 9999,
  loop: false,
  toolbar: {
    zoomIn: true,
    zoomOut: true,
    oneToOne: false,
    reset: true,
    prev: true,
    play: {
      show: true,
      size: "large"
    },
    next: true,
    rotateLeft: true,
    rotateRight: true,
    flipHorizontal: false,
    flipVertical: false
  }
});
Vue.config.devtools = true; // set ElementUI lang to EN

Vue.use(ElementUI, {
  zIndex: 1000
}); //全局过滤器

Vue.filter("dateFmt", function (input, formatString) {
  //es5函数参数设置默认值
  var lastFormatString = formatString || ""; // moment(input) 把时间字符串转成时间对象
  // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串

  return moment(input).format(formatString);
});
Vue.directive("loadmore", {
  bind: function bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    var SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
    SELECTWRAP_DOM.addEventListener("scroll", function () {
      var CONDITION = (this.scrollHeight - this.scrollTop).toFixed(0) <= this.clientHeight;

      if (CONDITION) {
        binding.value();
      }
    });
  }
}); // 格式化金额

Vue.filter("formatMoney", function (data) {
  if (!data) return "0"; // 将数据分割，保留两位小数

  data = data.toFixed(0); // 获取整数部分

  var intPart = Math.trunc(data); // 整数部分处理，增加,

  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 预定义小数部分

  var floatPart = ".00"; // 将数据分割为小数部分和整数部分

  var newArr = data.toString().split(".");

  if (newArr.length === 2) {
    // 有小数部分
    floatPart = newArr[1].toString(); // 取得小数部分

    return intPartFormat;
  }

  return intPartFormat;
});
Vue.prototype.HttpConstant = HttpConstant;
Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});