import _objectSpread from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import StorageHandler from "@/utils/StorageHandler";
import SystemConfig from "@/config/SystemConfig";
export default {
  name: "change-theme",
  props: {
    width: {
      type: String,
      default: '60vw'
    }
  },
  computed: _objectSpread({}, mapGetters([])),
  data: function data() {
    return {
      isShowDialog: true,
      theme: '',
      mainColor: SystemConfig.defaultColor.mainColor,
      secondColor: SystemConfig.defaultColor.secondColor,
      beforMainColor: '',
      beforSecondColor: ''
    };
  },
  created: function created() {
    this.beforMainColor = document.documentElement.style.getPropertyValue('--mainThemeColor');
    this.beforSecondColor = document.documentElement.style.getPropertyValue('--secondThemeColor');
    this.mainColor = this.beforMainColor;
    this.secondColor = this.beforSecondColor;
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    //选择主题
    handleChangeTheme: function handleChangeTheme(mainThemeColor, secondThemeColor) {
      this.mainColor = mainThemeColor;
      this.secondColor = secondThemeColor;
      document.documentElement.style.setProperty('--mainThemeColor', mainThemeColor);
      document.documentElement.style.setProperty('--secondThemeColor', secondThemeColor);
    },
    handleChangeMainTheme: function handleChangeMainTheme(val) {
      document.documentElement.style.setProperty('--mainThemeColor', val);
    },
    handleChangeSecondTheme: function handleChangeSecondTheme(val) {
      document.documentElement.style.setProperty('--secondThemeColor', val);
    },
    //保存
    saveTheme: function saveTheme() {
      var storageHandler = new StorageHandler();
      storageHandler.setSessionStorage('--mainThemeColor', this.mainColor);
      storageHandler.setSessionStorage('--secondThemeColor', this.secondColor);
      this.closeDialog();
    },
    //取消
    cancleDialog: function cancleDialog() {
      document.documentElement.style.setProperty('--mainThemeColor', this.beforMainColor);
      document.documentElement.style.setProperty('--secondThemeColor', this.beforSecondColor);
      this.closeDialog();
    }
  }
};