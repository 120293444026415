var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "upload-component-class" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            name: "file",
            headers: _vm.config,
            data: _vm.uploadObjs,
            action: _vm.url,
            "before-upload": _vm.beforeAvatarUpload,
            "on-success": _vm.handleAvatarSuccess,
            "on-progress": _vm.handleGoingFn,
            "show-file-list": false,
          },
        },
        [
          _c("el-button", { attrs: { type: _vm.type } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }