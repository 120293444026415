var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-header",
    { staticClass: "sj-header" },
    [
      _c("div", { staticClass: "sj-toolbar" }, [
        _c("div", { staticClass: "sj-header-left" }),
        _vm._v(" "),
        _c("div", { staticClass: "sj-header-right" }, [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c("el-tag", { staticClass: "topTag" }, [
                _vm._v(_vm._s(_vm.userInfo.name)),
              ]),
              _vm._v(" "),
              !this.isMobile()
                ? _c("span", { staticClass: "color-fff" }, [_vm._v("|")])
                : _vm._e(),
              _vm._v(" "),
              !this.isMobile()
                ? _c(
                    "el-dropdown",
                    { on: { command: _vm.handlePersonCommand } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "el-dropdown-link",
                          staticStyle: { margin: "0" },
                        },
                        [
                          _c(
                            "el-tag",
                            {
                              staticClass: "topTag",
                              staticStyle: { cursor: "pointer" },
                            },
                            [_vm._v("个人中心")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "gerenxinxi" } },
                            [_vm._v("查看个人信息")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "xiugaimima" } },
                            [_vm._v("修改密码")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !this.isMobile()
                ? _c("span", { staticClass: "color-fff" }, [_vm._v("|")])
                : _vm._e(),
              _vm._v(" "),
              !this.isMobile()
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "全屏",
                        placement: "bottom",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-full-screen color-fff",
                        on: { click: _vm.handleScreen },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "color-fff" }, [_vm._v("|")]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "退出系统",
                    placement: "bottom",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-logout color-fff",
                    on: { click: _vm.logout },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("tags"),
      _vm._v(" "),
      _vm.isShowChangeThemeDialog
        ? _c("change-theme", { on: { close: _vm.closeThemeDialog } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowModifyPassword
        ? _c("modify-password", { on: { close: _vm.closeModifyPassword } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPersonInfo
        ? _c("person-info", { on: { close: _vm.closeModifyPassword } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }