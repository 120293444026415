import Vue from 'vue';
import baseSelect from "./base-select/base-select";
import imgUpload from "./img-upload/img-upload";
import zipUpload from "./zip-upload/zip-load";
import imgUp from "./imgupload";
import videoUpload from "./videoupload";
import selectEnID from "./selectEnID";
import fileupload from "./fileupload";
import dirUpload from "./dirUpload";
import editor from "./editor";
Vue.component('baseSelect', baseSelect);
Vue.component('imgUpload', imgUpload);
Vue.component('imgUp', imgUp);
Vue.component('videoUpload', videoUpload);
Vue.component('zipUpload', zipUpload);
Vue.component('selectEnid', selectEnID);
Vue.component('fileUpload', fileupload);
Vue.component('dirUpload', dirUpload);
Vue.component('editor', editor);