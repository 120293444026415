import "core-js/modules/web.dom.iterable";
import StorageHandler from "@/utils/StorageHandler.js";
var storageHandler = new StorageHandler();
var tagObj = {
  label: '',
  value: '',
  query: '',
  num: '',
  close: true
};
var homeTag = {
  label: '首页',
  value: '/home',
  close: false
};

function setFistTag(list) {
  // if (list.length == 1) {
  //   list[0].close = false;
  // } else {
  //   list.some(a => {
  //     a.close = true
  //   })
  // }
  return list;
}

var tabs = {
  state: {
    selectedTags: storageHandler.getSessionStorage("selectedTags") || [homeTag],
    currentTag: storageHandler.getSessionStorage('currentTag') || tagObj,
    homeTag: homeTag
  },
  actions: {
    //添加标签页
    addTag: function addTag(_ref, data) {
      var commit = _ref.commit,
          state = _ref.state;
      commit('ADD_TAG', data);
    },
    //设置当前标签页
    setTag: function setTag(_ref2, data) {
      var commit = _ref2.commit,
          state = _ref2.state;
      commit('SET_TAG', data);
    },
    //删除某标签页
    removeTag: function removeTag(_ref3, data) {
      var commit = _ref3.commit,
          state = _ref3.state;
      commit('DEL_TAG', data);
    },
    //清除其他标签页
    clearOtherTag: function clearOtherTag(_ref4) {
      var commit = _ref4.commit,
          state = _ref4.state;
      commit('DEL_OTHER_TAG');
    },
    //清除标签页
    clearTag: function clearTag(_ref5) {
      var commit = _ref5.commit,
          state = _ref5.state;
      commit('DEL_ALL_TAG');
    }
  },
  mutations: {
    ADD_TAG: function ADD_TAG(state, action) {
      state.currentTag = action;
      storageHandler.setSessionStorage("currentTag", state.currentTag);
      if (state.selectedTags.some(function (a) {
        return a.value === action.value;
      })) return;
      state.selectedTags.push({
        label: action.label,
        value: action.value,
        query: action.query,
        close: true
      });
      state.selectedTags = setFistTag(state.selectedTags);
      storageHandler.setSessionStorage("selectedTags", state.selectedTags);
    },
    SET_TAG: function SET_TAG(state, value) {
      state.selectedTags.forEach(function (ele, num) {
        if (ele.value === value) {
          state.currentTag = state.selectedTags[num];
          storageHandler.setSessionStorage("currentTag", state.currentTag);
        }
      });
    },
    DEL_TAG: function DEL_TAG(state, action) {
      state.selectedTags.forEach(function (ele, num) {
        if (ele.value === action.value) {
          state.selectedTags.splice(num, 1);
          state.selectedTags = setFistTag(state.selectedTags);
          storageHandler.removeSessionStorage("currentTag");
          storageHandler.setSessionStorage("selectedTags", state.selectedTags);
        }
      });
    },
    DEL_OTHER_TAG: function DEL_OTHER_TAG(state, action) {
      state.selectedTags.forEach(function (ele, num) {
        state.selectedTags = [];

        if (state.homeTag.value == state.currentTag.value || state.currentTag.value == '') {
          state.selectedTags.push(state.homeTag);
        } else {
          state.selectedTags.push(state.homeTag);
          state.selectedTags.push(state.currentTag);
        }

        storageHandler.setSessionStorage("selectedTags", state.selectedTags);
      });
    },
    DEL_ALL_TAG: function DEL_ALL_TAG(state, action) {
      state.currentTag = tagObj;
      state.selectedTags = [];
      state.selectedTags.push(state.homeTag);
      storageHandler.removeSessionStorage("currentTag");
      storageHandler.removeSessionStorage("selectedTags");
    }
  }
};
export default tabs;