var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customerDetail" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.isShow,
            width: _vm.width,
            center: "",
            "before-close": function () {
              return _vm.$emit("close")
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "form-wrapper" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formData",
                      staticClass: "demo-form-inline form-inline",
                      attrs: {
                        "label-width": "auto",
                        "label-position": "right",
                        model: _vm.formData,
                        rules: _vm.rules,
                        disabled: _vm.action == "view",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 15 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "账号：", prop: "account" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.formData.account,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "account",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.account",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "姓名：", prop: "names" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formData.names,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "names",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.names",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "证件类型：",
                                    prop: "cardType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.onChangeCardTypeFn },
                                      model: {
                                        value: _vm.formData.cardType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "cardType",
                                            $$v
                                          )
                                        },
                                        expression: "formData.cardType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.options.cardType,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formData.cardType == 1
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "正面照：",
                                        prop: "cardFront",
                                      },
                                    },
                                    [
                                      _c("img-upload", {
                                        model: {
                                          value: _vm.formData.cardFront,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "cardFront",
                                              $$v
                                            )
                                          },
                                          expression: "formData.cardFront",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formData.cardType == 1
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "反面照：",
                                        prop: "cardBack",
                                      },
                                    },
                                    [
                                      _c("img-upload", {
                                        model: {
                                          value: _vm.formData.cardBack,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "cardBack",
                                              $$v
                                            )
                                          },
                                          expression: "formData.cardBack",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "证件号码：",
                                    prop: "cardNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "18" },
                                    model: {
                                      value: _vm.formData.cardNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "cardNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.cardNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "所在地：", required: "" } },
                                [
                                  _c("el-cascader", {
                                    ref: "area1",
                                    attrs: {
                                      clearable: "",
                                      props: _vm.areaProps,
                                    },
                                    model: {
                                      value: _vm.formData.provinceCodeList,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "provinceCodeList",
                                          $$v
                                        )
                                      },
                                      expression: "formData.provinceCodeList",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "详细地址：",
                                    prop: "address",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formData.address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "address",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formData.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.action !== "view"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("保 存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.action == "view"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.action = "edit"
                        },
                      },
                    },
                    [_vm._v("编辑个人信息")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }