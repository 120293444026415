import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
import StorageHandler from '@/utils/StorageHandler';
export default {
  name: "ckeditor",
  props: ["content", "isEdit"],
  //从父组件转递的内容
  mounted: function mounted() {
    var _this = this;

    var self = this; // 渲染编辑器

    self.ckeditor = window.CKEDITOR.replace('content', {
      height: '280px' // filebrowserImageUploadUrl:'/api/file/upload?token='+
      // this.storageHandler.getStorage("userInfoFiance").token

    }); //定义编辑器的高度

    self.ckeditor.on('fileUploadRequest', function (evt) {
      console.log('-----------', evt); // const requestData = evt.data.requestData
      // const upload = requestData.upload
      // delete requestData.upload
      // requestData.iFile = upload
      // requestData.Authorization = this.storageHandler.getStorage("userInfoFiance").token

      var fileLoader = evt.data.fileLoader;
      var xhr = fileLoader.xhr;
      var formData = new FormData();
      formData.append('file', fileLoader.file, fileLoader.fileName); // formData.append('owner', uuid);
      // formData.append('spaceCode', 'default');

      xhr.open('POST', fileLoader.uploadUrl, true);
      xhr.setRequestHeader('Authorization', _this.storageHandler.getStorage("userInfoFiance").token);
      xhr.send(formData);
      evt.stop();
    });
    self.ckeditor.on('fileUploadResponse', function (evt) {
      evt.stop();
      var data = evt.data;
      var fileLoader = data.fileLoader;
      var res = JSON.parse(fileLoader.xhr.responseText);

      if (res.code !== 200) {
        data.message = '上传失败';
        evt.cancel();
        return;
      }

      data.fileName = fileLoader.fileName;
      data.url = res.data.fileUrl;
      data.message = '上传成功';
    }); // 设置初始内容

    self.ckeditor.setData(self.content); // 监听内容变更事件

    self.ckeditor.on("change", function () {
      self.$emit("sendContnet", self.ckeditor.getData());
    });

    if (this.isEdit != 'new' && this.isEdit != 'edit') {
      self.ckeditor.on('instanceReady', function (ev) {
        this.isloadingFlag = true;
        var ed = ev.editor;
        ed.setReadOnly(true);
      });
    }
  },
  methods: {
    setDataFn: function setDataFn() {
      var _this2 = this;

      setTimeout(function () {
        _this2.ckeditor.setData(_this2.content);
      }, 800);
    }
  },
  data: function data() {
    return {
      id: parseInt(Math.random() * 10000).toString(),
      ckeditor: null,
      isloadingFlag: false,
      storageHandler: new StorageHandler()
    };
  },
  watch: {
    // 监听prop的变化，更新ckeditor中的值
    content: function content() {
      if (this.content !== this.ckeditor.getData()) {
        // this.ckeditor.setData(this.content);
        this.setDataFn();
      }
    }
  }
};