var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sj-tags" }, [
    _c(
      "div",
      { ref: "tagBox", staticClass: "tags-box" },
      [
        _c(
          "div",
          {
            ref: "tagsList",
            staticClass: "tags-list",
            on: { mousewheel: _vm.hadelMousewheel },
          },
          _vm._l(_vm.selectedTags, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                ref: "tagsPageOpened",
                refInFor: true,
                staticClass: "tag-item",
                class: { "is-active": _vm.nowTagValue == item.value },
                attrs: { name: item.value },
                on: {
                  click: function ($event) {
                    return _vm.openUrl(item)
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "tag-text",
                    class: { "no-close": item.close != true },
                  },
                  [_vm._v(_vm._s(item.label))]
                ),
                _vm._v(" "),
                item.close
                  ? _c("i", {
                      staticClass: "el-icon-close tag-close",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeTag(item)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "el-dropdown",
          { staticClass: "tags-menu" },
          [
            _c(
              "el-button",
              {
                staticClass: "nav-tags",
                attrs: { type: "primary", size: "mini" },
              },
              [
                _vm._v("\n        更多\n        "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeOthersTags($event)
                      },
                    },
                  },
                  [_vm._v("关闭其他")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeAllTags($event)
                      },
                    },
                  },
                  [_vm._v("关闭全部")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "contextmenu" }, [
      _c(
        "li",
        {
          on: {
            click: function ($event) {
              return _vm.closeTag(_vm.currTag)
            },
          },
        },
        [_vm._v("关闭")]
      ),
      _vm._v(" "),
      _c("li", { on: { click: _vm.closeOthersTags } }, [_vm._v("关闭其他")]),
      _vm._v(" "),
      _c("li", { on: { click: _vm.closeAllTags } }, [_vm._v("关闭全部")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }