var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: _vm.size,
            filterable: _vm.filterable,
            multiple: _vm.multiple,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            clearable: _vm.clearable,
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.text,
            callback: function ($$v) {
              _vm.text = $$v
            },
            expression: "text",
          },
        },
        _vm._l(_vm.dataSource, function (item) {
          return _c("el-option", {
            key: item.dictId,
            attrs: { label: item[_vm.labelKey], value: item[_vm.valueKey] },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }