var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "el-select",
        {
          ref: "setQuerys",
          attrs: {
            disabled: _vm.disabled,
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            placeholder: "请输入企业",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loadinglist,
          },
          on: { change: _vm.handleSelect },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.breedoptions, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }