var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      staticClass: "avatar-uploader",
      attrs: {
        name: "file",
        action: "/guns-cloud-file/upload",
        headers: _vm.config,
        "show-file-list": false,
        "on-success": _vm.handleAvatarSuccess,
        "before-upload": _vm.beforeAvatarUpload,
        "on-error": _vm.handleError,
        disabled: _vm.isDetail,
      },
    },
    [
      _vm.imgvidtf == 1
        ? _c(
            "video",
            {
              staticClass: "avatar video-avatar",
              attrs: { controls: "controls", src: _vm.Listimg },
            },
            [_vm._v("\n    您的浏览器不支持视频播放\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.imgvidtf == 0
        ? _c("img", {
            staticClass: "avatar video-avatar",
            attrs: { controls: "controls", src: _vm.Listimg },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imgvidtf == 3
        ? _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }