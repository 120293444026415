import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StorageHandler from "@/utils/StorageHandler.js";
export default {
  name: "img-upload",
  data: function data() {
    return {
      storageHandler: new StorageHandler(),
      loading: false,
      imageUrl: this.value,
      uploadPercent: 0
    };
  },
  props: {
    value: {
      type: String
    },
    isDetail: {
      type: Boolean
    },
    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: '100px'
    },
    lineHeight: {
      type: String,
      default: '100px'
    }
  },
  computed: {
    config: function config() {
      return {
        "Authorization": this.storageHandler.getSessionStorage("userInfoFiance").token
      };
    }
  },
  watch: {
    value: function value(curVal, oldVal) {
      this.imageUrl = curVal;
    }
  },
  methods: {
    //上传成功后
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      if (res.code === 10006) {
        this.$message({
          message: '登录已过期，请重新登录',
          type: 'warning'
        });
        this.storageHandler.clearSessionStorage();
        this.$router.replace({
          path: '/login'
        });
      } else {
        this.loading = false;
        this.imageUrl = res.data.fileUrl;
        var img = this.imageUrl;
        this.$emit('input', img);
      }
    },
    //上传之前
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';

      if (!isJPG) {
        this.$message.error('上传的图片只能是 JPG/JPEG/PNG 格式!');
      }

      return isJPG;
    },
    //上传过程中
    handleLoading: function handleLoading(event, file, fileList) {
      this.loading = true;
      this.uploadPercent = Number(file.percentage.toFixed(0));
    },
    //上传失败
    handleError: function handleError(file, fileList) {
      this.loading = false;
      this.$message.error('上传失败！');
    }
  }
};