var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.menus, function (value) {
        return [
          value.children.length > 0
            ? _c(
                "el-submenu",
                { key: value.id, attrs: { index: value.menuUrl } },
                [
                  _c("template", { slot: "title" }, [
                    value.menuIcon
                      ? _c("i", { staticClass: "bi", class: value.menuIcon })
                      : _c("i", { staticClass: "el-icon-s-tools" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: { "el-menu--display": _vm.isCollapse },
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_vm._v(_vm._s(value.menuName))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("sidebar-item", {
                    attrs: {
                      menus: value.children,
                      isCollapse: _vm.isCollapse,
                    },
                  }),
                ],
                2
              )
            : _c(
                "el-menu-item",
                {
                  key: value.id,
                  attrs: { index: value.menuUrl },
                  on: {
                    click: function ($event) {
                      return _vm.open(value)
                    },
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    value.menuIcon
                      ? _c("i", { staticClass: "bi", class: value.menuIcon })
                      : _c("i", { staticClass: "el-icon-s-tools" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: { "el-menu--display": _vm.isCollapse },
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_vm._v(_vm._s(value.menuName))]
                    ),
                  ]),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }