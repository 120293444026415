import StorageHandler from '@/utils/StorageHandler';
import SystemConfig from '@/config/SystemConfig';
var storageHandler = new StorageHandler();
var common = {
  state: {
    token: "" || storageHandler.getSessionStorage('token'),
    menus: [],
    tags: [],
    currentTag: {},
    AnimalType: storageHandler.getStorage('AnimalType'),
    collapseFlag: false
  },
  actions: {
    // 缓存是否显示轮播图
    setToken: function setToken(_ref, data) {
      var commit = _ref.commit,
          state = _ref.state,
          dispatch = _ref.dispatch;
      commit("SET_TOKEN", data);
    },
    // 缓存当前选中的栏目
    clearToken: function clearToken(_ref2, data) {
      var commit = _ref2.commit,
          state = _ref2.state,
          dispatch = _ref2.dispatch;
      commit("CLEAR_TOKEN", data);
    },
    // 缓存当前菜单
    setMenus: function setMenus(_ref3, data) {
      var commit = _ref3.commit,
          state = _ref3.state,
          dispatch = _ref3.dispatch;
      commit("SET_MENUS", data);
    },
    // 缓存左侧导航栏收缩状态
    setCollapse: function setCollapse(_ref4, data) {
      var commit = _ref4.commit,
          state = _ref4.state,
          dispatch = _ref4.dispatch;
      commit("SET_COLLAPSE", data);
    },
    setAnimal: function setAnimal(_ref5, data) {
      var commit = _ref5.commit,
          state = _ref5.state,
          dispatch = _ref5.dispatch;
      commit("SET_ANIMAL", data);
    }
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, data) {
      console.log('我是存储token的内容的方法');
      storage.setStorage("token", data, SystemConfig.storate.sessionStorageKey);
      state.token = data;
    },
    CLEAR_TOKEN: function CLEAR_TOKEN(state, data) {
      state.token = "";
    },
    SET_MENUS: function SET_MENUS(state, data) {
      state.menus = data;
    },
    SET_COLLAPSE: function SET_COLLAPSE(state, data) {
      state.collapseFlag = data;
    },
    SET_ANIMAL: function SET_ANIMAL(state, data) {
      storageHandler.setStorage("AnimalType", data);
      state.AnimalType = data;
    }
  }
};
export default common;