import _objectSpread from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import StorageHandler from "@/utils/StorageHandler.js";
import tags from "./tags";
import changeTheme from '@/layout/change-theme';
import screenfull from 'screenfull';
import ModifyPassword from "./modify_password";
import UserApi from '@/api/UserApi';
import LoginApi from '@/api/LoginApi';
import { validateHttpURL } from '@/utils/validate';
import personInfo from "./components/personInfo.vue";
export default {
  name: "index",
  components: {
    tags: tags,
    changeTheme: changeTheme,
    ModifyPassword: ModifyPassword,
    personInfo: personInfo
  },
  data: function data() {
    return {
      loginApi: new LoginApi(),
      // collapseFlag: false,
      storageHandler: new StorageHandler(),
      isShowChangeThemeDialog: false,
      //控制更换主题弹窗是否显示
      isFullscreen: false,
      isShowModifyPassword: false,
      //控制修改密码弹窗是否显示
      appList: [],
      showPersonInfo: false //是否展示个人信息弹窗

    };
  },
  created: function created() {// let http = new UserApi()
    // http.appList({pageSize:9999}).then(res => {
    //   this.appList = res.data.records
    // })
  },
  mounted: function mounted() {},
  computed: _objectSpread({}, mapGetters(['selectedTags', 'userInfo', 'collapseFlag'])),
  props: {},
  methods: {
    collapseFlagChange: function collapseFlagChange() {
      this.$store.dispatch('setCollapse', !this.collapseFlag);
    },
    logout: function logout() {
      var _this = this;

      this.$confirm('此操作将退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var loading = _this.$loading({
          lock: true,
          text: "请耐心等待.....",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        var http = new LoginApi();
        http.logoutAuthFn().then(function (res) {
          if (res.code == 200) {
            _this.storageHandler.clearSessionStorage();

            _this.$router.push({
              path: '/login'
            });
          } else {
            _this.$message.error(res.message);
          }

          loading.close();
        }).catch(function (e) {
          loading.close();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消退出'
        });
      });
    },
    //关闭更换主题弹窗
    closeThemeDialog: function closeThemeDialog() {
      this.isShowChangeThemeDialog = false;
    },
    handlePersonCommand: function handlePersonCommand(command) {
      if (command == 'gerenxinxi') {
        this.showPersonInfo = true;
      } else if (command == 'genghuanpifu') {
        this.isShowChangeThemeDialog = true;
      } else if (command == 'xiugaimima') {
        this.isShowModifyPassword = true;
      }
    },
    //关闭修改密码弹窗
    closeModifyPassword: function closeModifyPassword() {
      this.isShowModifyPassword = false;
      this.showPersonInfo = false;
    },
    handleScreen: function handleScreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        });
        return false;
      }

      screenfull.toggle();
    },
    appJump: function appJump(val) {
      if (val.appJumpAddress) {
        if (validateHttpURL(val.appJumpAddress)) {
          window.open(val.appJumpAddress);
        } else {
          this.$message.warning('请联系管理员填写正确的应用url！');
        }
      } else {
        this.$message.warning('请联系管理员配置应用url！');
      }
    }
  }
};