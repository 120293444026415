import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StorageHandler from "@/utils/StorageHandler.js";
export default {
  name: "img-upload",
  data: function data() {
    return {
      storageHandler: new StorageHandler(),
      loading: false,
      imageUrl: this.value,
      uploadPercent: 0,
      fileList: [],
      img: "",
      Listimg: [],
      imgUrl1: this.imgUrl,
      arr: []
    };
  },
  props: {
    value: {
      type: String
    },
    isDetail: {
      type: Boolean
    },
    imgUrl: {
      type: String
    }
  },
  computed: {
    config: function config() {
      return {
        Authorization: this.storageHandler.getSessionStorage("userInfoFiance").token
      };
    }
  },
  watch: {
    value: function value(curVal, oldVal) {
      this.imgUrl1 = curVal;

      if (this.imgUrl1 != null) {
        this.handleImg();
      }
    },
    imgUrl: function imgUrl(curVal, oldVal) {
      this.imgUrl1 = curVal;

      if (this.imgUrl1 != null) {
        this.handleImg();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handleImg: function handleImg() {
      var arrs = this.imgUrl1.split(",");

      for (var i in arrs) {
        this.fileList.push({
          url: arrs[i]
        });
        this.Listimg.push({
          url: arrs[i]
        });
      }
    },
    handleRemove: function handleRemove(file) {
      var _this = this;

      var fileurl;

      if (file.url != null && file.url.indexOf("blob") == -1) {
        fileurl = file.url;
      } else {
        fileurl = file.response.data.fileUrl;
      }

      var _loop = function _loop(i) {
        if (fileurl == _this.Listimg[i].url) {
          var index = _this.Listimg.findIndex(function (text) {
            return text.url == _this.Listimg[i].url;
          });

          _this.Listimg.splice(index, 1);
        }
      };

      for (var i in this.Listimg) {
        _loop(i);
      }

      var arr = [];
      this.Listimg.forEach(function (e) {
        return arr.push(e.url);
      });
      this.img = arr.join(",");
      this.$emit("input", this.img);
    },
    handleChange: function handleChange(res, file, fileList) {},
    //上传成功后
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      console.log('xxxxxxx', response);
      this.Listimg.push({
        url: response.data.fileUrl
      });
      var arr = [];
      this.Listimg.forEach(function (e) {
        return arr.push(e.url);
      });
      this.img = arr.join(",");
      this.$emit("input", this.img);
    },
    //上传之前
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传的图片只能是 JPG/JPEG/PNG 格式!");
      }

      return isJPG;
    },
    //上传过程中
    handleLoading: function handleLoading(event, file, fileList) {
      this.loading = true;
      this.uploadPercent = Number(file.percentage.toFixed(0));
    },
    //上传失败
    handleError: function handleError(file, fileList) {
      this.loading = false;
      this.$message.error("上传失败！");
    }
  }
};