var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "avatar-uploader",
          style: {
            width: _vm.width,
            height: _vm.height,
            lineHeight: _vm.lineHeight,
          },
          attrs: {
            action: "/trading-market-admin/file/upload",
            headers: _vm.config,
            name: "file",
            "show-file-list": false,
            disabled: _vm.isDetail,
            "on-success": _vm.handleAvatarSuccess,
            "on-progress": _vm.handleLoading,
            "on-error": _vm.handleError,
            "before-upload": _vm.beforeAvatarUpload,
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageUrl,
                expression: "imageUrl",
              },
            ],
            staticClass: "avatar",
            style: { width: _vm.width, height: _vm.height },
            attrs: { width: "100%", src: _vm.imageUrl, fit: "cover", alt: "" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.imageUrl,
                expression: "!imageUrl",
              },
            ],
            staticClass: "el-icon-plus avatar-uploader-icon",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }