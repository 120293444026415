import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Layout from "@/layout";
export var constantRoutes = [{
  path: "/login",
  component: function component() {
    return import("@/views/login/index.vue");
  },
  hidden: true
}, // 运营管理-运营大屏
{
  path: "/operationManagement/screenLink",
  name: "operationScreen",
  component: function component() {
    return import("@/views/operationManagement/operationScreen/screen");
  },
  meta: {
    title: "运营大屏"
  }
}, {
  path: "/operationManagement/operationScreen",
  name: "operationScreen",
  component: function component() {
    return import("@/views/operationManagement/operationScreen/screenLink");
  },
  meta: {
    title: "运营大屏-跳转"
  }
}, {
  path: "/operationManagement",
  component: Layout,
  children: [{
    path: "car",
    name: "vehicleManagement",
    component: function component() {
      return import("@/views/operationManagement/vehicleManagement");
    },
    meta: {
      title: "车辆管理",
      keepAlive: true
    }
  }, {
    path: "monitoringLiveStreaming",
    name: "monitorLive",
    component: function component() {
      return import("@/views/operationManagement/monitorLive");
    },
    meta: {
      title: "监控直播",
      keepAlive: true
    }
  }, {
    path: "remoteAssistance",
    name: "remoteHelp",
    component: function component() {
      return import("@/views/operationManagement/remoteHelp");
    },
    meta: {
      title: "远程协助",
      keepAlive: true
    }
  }, {
    path: "lanquanLeasing",
    name: "dormitoryLease",
    component: function component() {
      return import("@/views/operationManagement/dormitoryLease");
    },
    meta: {
      title: "栏圈租赁",
      keepAlive: true
    }
  }, {
    path: "inspectionRecord",
    name: "patrolRecord",
    component: function component() {
      return import("@/views/operationManagement/patrolRecord");
    },
    meta: {
      title: "巡检记录",
      keepAlive: true
    }
  }, {
    path: "/operationManagement/inspectionResult",
    name: "inspectionResult",
    component: function component() {
      return import("@/views/operationManagement/inspectionResult");
    },
    meta: {
      title: "巡检结果",
      keepAlive: true
    }
  }, {
    path: "imageSetting",
    name: "videoConfig",
    component: function component() {
      return import("@/views/operationManagement/videoConfig");
    },
    meta: {
      title: "影像配置",
      keepAlive: true
    }
  }, {
    path: "role",
    name: "statisticsScreenLink",
    component: function component() {
      return import("@/views/operationManagement/roleManagement");
    },
    meta: {
      title: "角色管理",
      keepAlive: true
    }
  }, {
    path: "versionManage",
    name: "versionManage",
    component: function component() {
      return import("@/views/operationManagement/versionManage");
    },
    meta: {
      title: "版本管理",
      keepAlive: true
    }
  }, {
    path: '/operationManagement/screenInfo',
    name: 'largeScreenMaintain',
    component: function component() {
      return import('@/views/screen/largeScreenMaintain');
    },
    meta: {
      title: '大屏维护',
      keepAlive: true
    }
  }]
}, {
  path: "/",
  component: Layout,
  redirect: "/home",
  children: [{
    path: "/home",
    name: "homepageindex",
    component: function component() {
      return import("@/views/home/index.vue");
    },
    meta: {
      title: "主页",
      keepAlive: true
    }
  }]
}, // 人员管理
{
  path: "/personnelManagement",
  component: Layout,
  children: [{
    path: "/personnelManagement/user",
    name: "user",
    component: function component() {
      return import("@/views/personnelManagement/user.vue");
    },
    meta: {
      title: "用户信息",
      keepAlive: true
    }
  }, {
    path: "/personnelManagement/driver",
    name: "driver",
    component: function component() {
      return import("@/views/personnelManagement/driver.vue");
    },
    meta: {
      title: "司机信息",
      keepAlive: true
    }
  }, {
    path: "/personnelManagement/intermediary",
    name: "intermediary",
    component: function component() {
      return import("@/views/personnelManagement/intermediary.vue");
    },
    meta: {
      title: "中介信息",
      keepAlive: true
    }
  }, {
    path: "/personnelManagement/manager",
    name: "manager",
    component: function component() {
      return import("@/views/personnelManagement/manager.vue");
    },
    meta: {
      title: "管理人员",
      keepAlive: true
    }
  }]
}, // 设备管理
{
  path: "/deviceManagement",
  component: Layout,
  children: [{
    path: "/deviceManagement/weighbridge",
    name: "weighbridge",
    component: function component() {
      return import("@/views/deviceManagement/weighbridge.vue");
    },
    meta: {
      title: "地磅管理",
      keepAlive: true
    }
  }, {
    path: "/deviceManagement/camera",
    name: "deviceManagement",
    component: function component() {
      return import("@/views/deviceManagement/deviceManagement.vue");
    },
    meta: {
      title: "摄像头管理",
      keepAlive: true
    }
  }]
}, // 进场管理
{
  path: " /mobilizationManagement",
  component: Layout,
  children: [{
    path: "/mobilizationManagement/mobilizationRegiste",
    name: "mobilizationRegiste",
    component: function component() {
      return import("@/views/registrationMenu/registrationRecord.vue");
    },
    meta: {
      title: "进场登记",
      keepAlive: true
    }
  }]
}, // 牲畜管理
{
  path: "/animalManage",
  component: Layout,
  children: [{
    path: "/animalManage/animalQuery",
    name: "animalQuery",
    component: function component() {
      return import("@/views/animalManageMenu/animalQuery.vue");
    },
    meta: {
      title: "牲畜查询",
      keepAlive: true
    }
  }]
}, // 交易管理
{
  path: "/transactionManage",
  component: Layout,
  children: [{
    path: "/transactionManage/order",
    name: "animalQuery",
    component: function component() {
      return import("@/views/transactionManage/order.vue");
    },
    meta: {
      title: "订单",
      keepAlive: true
    }
  }, {
    path: "/transactionManage/transactionRecord",
    name: "animalQuery",
    component: function component() {
      return import("@/views/transactionManage/transactionRecord.vue");
    },
    meta: {
      title: "交易记录",
      keepAlive: true
    }
  }]
}, // 出场管理
{
  path: "/exitManagement",
  component: Layout,
  children: [{
    path: "/exitManagement/exitDelivery",
    name: "animalQuery",
    component: function component() {
      return import("@/views/exitManagement/exitDelivery.vue");
    },
    meta: {
      title: "出场交割",
      keepAlive: true
    }
  }]
}, // 分享页面
{
  path: "/shared",
  component: function component() {
    return import("@/views/share/shared.vue");
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return import("@/views/404.vue");
  },
  hidden: true
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;