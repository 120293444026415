import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StorageHandler from "@/utils/StorageHandler.js";
export default {
  name: "zip-upload",
  data: function data() {
    return {
      storageHandler: new StorageHandler(),
      loading: false,
      imageUrl: this.value,
      uploadPercent: 0,
      url: "https://z3.ax1x.com/2021/05/10/gtmA5q.jpg"
    };
  },
  props: {
    value: {
      type: String
    },
    isDetail: {
      type: Boolean
    }
  },
  computed: {
    config: function config() {
      return {
        "Authorization": this.storageHandler.getSessionStorage('tokenFiance')
      };
    }
  },
  watch: {
    value: function value(curVal, oldVal) {
      this.imageUrl = curVal;
    }
  },
  methods: {
    //上传成功后
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.$loading = false;
      this.imageUrl = res.data.url;
      var img = this.imageUrl;
      this.$emit('input', res);
    },
    //上传过程中
    handleLoading: function handleLoading(event, file, fileList) {
      this.$loading = true;
      this.uploadPercent = Number(file.percentage.toFixed(0));
    },
    //上传失败
    handleError: function handleError(file, fileList) {
      this.loading = false;
    }
  }
};