import "core-js/modules/web.dom.iterable";
import _ from "lodash"; //线转树形结构

export function listToTree(lists) {
  var map = {};
  var list = lists;
  list.forEach(function (item) {
    if (!map[item.nodeId]) {
      map[item.nodeId] = item;
    }
  });
  list.forEach(function (item) {
    if (item.nodePid != -2 && map[item.nodePid]) {
      map[item.nodePid].children ? map[item.nodePid].children.push(item) : map[item.nodePid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.nodePid == -2) {
      return item;
    }
  }); // let endArr = cloneDate.fllter(father => {
  //   let children = cloneDate.filter(child => child.nodeId == father.nodeId)
  //   if (children.length > 0) {
  //     father.children = children
  //   }
  // })
  // return endArr
}
export function listToTreeg(lists) {
  var map = {};
  var list = lists;
  list.forEach(function (item) {
    if (!map[item.id]) {
      map[item.id] = item;
    }
  });
  list.forEach(function (item) {
    if (item.pid != -1 && map[item.pid]) {
      map[item.pid].children ? map[item.pid].children.push(item) : map[item.pid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.pid == 0) {
      return item;
    }
  });
} // export function listToTreePid(lists) {
//     let map = {}
//     let list = lists
//     list.forEach(function (item) {
//       if (!map[item.id]) {
//         map[item.id] = item
//       }
//     });
//     list.forEach(item => {
//       if (item.pid != -2 && map[item.pid]) {
//         map[item.pid].children ? map[item.pid].children.push(item) : map[item.pid].children = [item];
//       }
//     })
//     return list.filter(item => {
//       if (item.pid == 0) {
//         return item
//       }
//     })
//   }

export function listToTreeFarm(lists) {
  var map = {};
  var list = lists;
  list.forEach(function (item) {
    if (!map[item.id]) {
      map[item.id] = item;
    }
  });
  list.forEach(function (item) {
    if (item.parentId != -2 && map[item.parentId]) {
      map[item.parentId].children ? map[item.parentId].children.push(item) : map[item.parentId].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.parentId == 0) {
      return item;
    }
  });
}
export function listToTreeadds(lists) {
  var map = {};
  var list = lists;
  list.forEach(function (item) {
    if (!map[item.addressId]) {
      map[item.addressId] = item;
    }
  });
  list.forEach(function (item) {
    if (item.pid != -2 && map[item.pid]) {
      map[item.pid].children ? map[item.pid].children.push(item) : map[item.pid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.pid == 0) {
      return item;
    }
  });
}
export function listToTreePid(lists) {
  var map = {};
  var list = lists;
  list.forEach(function (item) {
    if (!map[item.id]) {
      map[item.id] = item;
    }
  });
  list.forEach(function (item) {
    if (item.pid != -2 && map[item.pid]) {
      map[item.pid].children ? map[item.pid].children.push(item) : map[item.pid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.pid == 0) {
      return item;
    }
  });
}
export function listToTreePid1(lists) {
  var map = {};
  var list = lists;
  list.forEach(function (item) {
    if (!map[item.id]) {
      map[item.id] = item;
    }
  });
  list.forEach(function (item) {
    if (item.pid != -2 && map[item.pid]) {
      map[item.pid].children ? map[item.pid].children.push(item) : map[item.pid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.pid == -1) {
      return item;
    }
  });
} // navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

export default function _isMobile() {
  return /Android|WebOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
} //扁平数据转树形数据  list代表需要转换的数据，id代表每一条的id键值名称，parentId代表父级id键值名称, topNode为顶级节点的父id

export function flatTurnTree(list) {
  var map = {};
  list.forEach(function (item) {
    if (!map[item.nodeId]) {
      map[item.nodeId] = item;
    }
  });
  list.forEach(function (item) {
    if (item.nodePid != -2 && map[item.nodePid]) {
      map[item.nodePid].children ? map[item.nodePid].children.push(item) : map[item.nodePid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.nodePid == -2) {
      return item;
    }
  });
}
export function flatTurnTreedob(list, id, pid) {
  var num = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -2;
  var map = {};
  list.forEach(function (item) {
    if (!map[item[id]]) {
      map[item[id]] = item;
    }
  });
  list.forEach(function (item) {
    if (item[pid] != num && map[item[pid]]) {
      map[item[pid]].children ? map[item[pid]].children.push(item) : map[item[pid]].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item[pid] == num) {
      return item;
    }
  });
}
export function flatTurnTreeType(list) {
  var map = {};
  list.forEach(function (item) {
    if (!map[item.nodeId]) {
      map[item.nodeId] = item;
    }
  });
  list.forEach(function (item) {
    if (item.nodePid != -2 && map[item.nodePid]) {
      map[item.nodePid].children ? map[item.nodePid].children.push(item) : map[item.nodePid].children = [item];
    }
  });
  return list.filter(function (item) {
    if (item.nodePid == -2) {
      item.isDisabled = true;
      return item;
    } else {
      item.isDisabled = false;
    }
  });
}