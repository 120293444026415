var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-aside",
        {
          staticClass: "sj-aside",
          class: {
            "is-collapse": _vm.collapseFlag,
            "no-collapse": !_vm.collapseFlag,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "sj-logo",
              class: {
                "is-collapse": _vm.collapseFlag,
                "no-collapse": !_vm.collapseFlag,
              },
            },
            [
              _c(
                "div",
                {
                  class: {
                    "is-show": !_vm.collapseFlag,
                    "no-show": _vm.collapseFlag,
                  },
                },
                [_vm._v("肉牛交易市场管理平台")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              attrs: {
                "default-active": _vm.$route.path,
                mode: "vertical",
                "show-timeout": 200,
                "text-color": "#fff",
                "active-text-color": "#ffd04b",
                "background-color": "#102d55",
                collapse: _vm.collapseFlag,
              },
            },
            [
              _c("sidebar-item", {
                attrs: { menus: _vm.menus, isCollapse: _vm.collapseFlag },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }